.para {
    color: var(--input-text);
    margin-top: 0px;
    padding-top: 0px;
}
.title {
    margin-bottom: 5px;
}
.invites {
    padding-top: 20px;
    height: 100%;
}
.invite-btn {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}
.email {
    background-color: #7ed4e6;
}
.text {
    background-color: #5cb85c;
}
.link {
    background-color: #216bc4;
}