.main {
    margin-left: 50px;
    margin-right: 50px;
    padding-top: 0px !important;
    margin-top: 0px !important;
}
.header {
    display: flex;
    justify-content: space-between;
    align-content:center;
}
.create-league {
    margin-top: 15px;
    background-color: var(--flow-blue);
    color: var(--white);
    font-weight: 600;
    width: 200px;
    height: 45px;
    border-radius: 10px;
    font-size: 21px;
    display: flex;
    justify-content: center;
}
.create-league-link {
    color:#fff;
    text-decoration: none;
    padding-top: 10px;
    padding-left: 18px;
    height: 45px;
    width: 200px;
}
