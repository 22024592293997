.draft-in-nav {
    height: 50px;
    width: 100%;
    background-color: #F8F8F8;
    display: flex;
    align-content: center;
}
.draft-nav-btn {
    background-color: transparent;
    height: 50px;
    border: none;
    font-size: 17px;
    outline: none;
    margin-right: 15px;
}
.active {
    font-weight: 600;
}
.draft-main {
    height: 100px;
}
.draft-before {
    position: sticky;
    top: 0px;
    display: block;
}
.switch {
position: relative;
display: inline-block;
width: 60px;
height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
opacity: 0;
width: 0;
height: 0;
}

/* The slider */
.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 26px;
width: 26px;
left: 4px;
bottom: 4px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
background-color: #99ccff;
}

input:focus + .slider {
box-shadow: 0 0 1px #99ccff;
}

input:checked + .slider:before {
-webkit-transform: translateX(26px);
-ms-transform: translateX(26px);
transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}
.draft-in-nav {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.autodraft-wrapper {
    display: flex;
    align-items: center;
}
.autodraft-label {
    margin-right: 10px;
    font-weight: 600;
}