.proposal-select {
    height:40px;
    color:#6e6d7a;
    background:#f3f3f4;
    box-shadow: 0px 0px 0px 1px #e7e7e9 inset;
    border-radius:8px;
    outline:none;
    padding-left: 6px;
    font-weight:500;
    font-size:15px;
    margin-left:20px;
    margin-right:20px;
}
.proposal-select:hover {
    border:none;
    box-shadow: 0px 0px 0px 0px #e7e7e9,0px 0px 0px 4px rgba(153, 204, 255,.5);
    color:#0d0c22;
}
.stats-select {
    height:40px;
    color:#6e6d7a;
    background:#f3f3f4;
    box-shadow: 0px 0px 0px 1px #e7e7e9 inset;
    border-radius:8px;
    outline:none;
    padding-left: 6px;
    font-weight:500;
    font-size:15px;
    margin-left:20px;
    margin-right:20px;
}
.stats-select:hover {
    border:none;
    box-shadow: 0px 0px 0px 0px #e7e7e9,0px 0px 0px 4px rgba(153, 204, 255,.5);
    color:#0d0c22;
}
.propose-a-trade-btn {
    height:35px;
    width:200px;
    background-color: var(--flow-blue);
    border:none;
    border-radius:20px;
    color:#fff;
    font-weight:500;
    font-size:17px;
    z-index: 10;
}
@media only screen and (max-width : 605px) {
    .propose-a-trade-btn {
        position: fixed;
        bottom:20px;
        margin-left: auto;
        margin-right: auto;
        left: 100px;
        right: 0;
        text-align: center;
        border-radius:10px;
        width:60%;
    }
}
.trade-selected {
    background-color: var(--flow-blue);
    color:#fff;
}
.trade-selected::before {
    content: '✓';
    padding-right:2px;
}
.trade-next {
    height:40px;
    width:120px;
    background-color: transparent;
    border: 2px solid var(--flow-blue);
    border-radius:10px;
    margin-top:10px;
    font-weight:500;
    color:var(--flow-blue);
    font-size:19px;
    transition: all .2s ease;
}
.trade-next:hover {
    background-color:var(--flow-blue);
    color:#fff;
}
.trade-propose {
    height:40px;
    width:120px;
    color:#fff;
    background-color:var(--flow-blue);
    border-radius:10px;
    margin-top:10px;
    font-weight:500;
    font-size:19px;
    border:none;
}
.cancel-trade {
    height:40px;
    border-radius:10px;
    border: 2px solid var(--danger);
    background:transparent;
    font-weight:500;
    color:var(--danger);
    font-size:15px;
}
.go-through-trade {
    height:40px;
    border-radius:10px;
    border: 2px solid var(--success);
    background:transparent;
    font-weight:500;
    color:var(--success);
    font-size:15px;
}
.trading {
    pointer-events: none;
    color: #eee;
    border-color: #eee;
}
.trade-card {
    min-width: 250px;
    height: 270px;
    border: 3px solid #000;
    border-radius: 15px;
    margin-bottom: 20px;
}
.trade-card-proposed {
    min-width: 250px;
    height: 217px;
    border: 3px solid #000;
    border-radius: 15px;
    margin-bottom: 20px;
}
.accept-trade {
    border: 2px solid var(--success);
    height:35px;
    width:100px;
    background-color:transparent;
    color:var(--success);
    border-radius:10px;
    font-weight:500;
    font-size:15px;
    transition: all .2s ease;
}
.accept-trade:hover {
    border: 2px solid var(--success);
    height:35px;
    width:100px;
    background-color:var(--success);
    color:#fff;
    border-radius:10px;
    font-weight:500;
    font-size:15px;

}
.decline-trade {
    border: 2px solid var(--danger);
    height:35px;
    width:100px;
    background-color:transparent;
    color:var(--danger);
    border-radius:10px;
    font-weight:500;
    font-size:15px;
    transition: all .2s ease;
}
.decline-trade:hover {
    border: 2px solid var(--danger);
    height:35px;
    width:100px;
    background-color:var(--danger);
    color:#fff;
    border-radius:10px;
    font-weight:500;
    font-size:15px;

}
.trade-action-loading {
    pointer-events: none;
    color: #e5e5e5;
    border-color: #e5e5e5;
}