.draft-nav {
    width: 100%;
    height: 60px;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.draft-brand {
    display: flex;
    align-items: center;
}
.draft-nav-logo {
    height: 50px;
}
.draft-nav-brand-name {
    font-size: 20px;
    font-family: 'BigJohn';
    color: var(--flow-blue);
    font-weight: 400;
    padding-top: 5px;
}
.exit-link {
    font-size: 18px;
    color: #000000;
    text-decoration: none;
    display: flex;
    margin-right: 10px;
}
.exit-icon {
    padding-left: 3px;
}
.exit-link:hover {
    text-decoration: underline;
}