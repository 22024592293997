.daily-contest-share {
    background-color: var(--flow-blue);
    color: #fff;
    height: 40px;
    width: 50px;
    font-weight: 400;
    font-size: 20px;
    border: none;
    border-radius: 10px;
}

 {
    border: 2px solid var(--flow-blue);
}
.daily-contest-share-icon {
    height: 25px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(257deg) brightness(104%) contrast(102%);
}
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
.daily-contest-share-modal {
    width: 300px;
    right:15px;
    top: 102px;
    position: absolute;
    background-color: #EEE;
    border-radius: 10px;
    display: none;
}
.daily-contest-share:hover .daily-contest-share-modal {
    display: block;
    -webkit-animation: fadeIn .3s;
    animation: fadeIn .3s;
}
.daily-contest-share-modal:hover {
    display: block;
}
.daily-contest-code {
    color: #000;
}
.daily-contest-share-copy {
    height: 40px;
    border: none;
    background-color: #5cb85c;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    margin-bottom: 20px;
}
.daily-contest-share-copy:focus {
     outline:none; 
     border:1px solid #4D90FE;
     -webkit-box-shadow: 0px 0px 5px  #4D90FE;
     box-shadow: 0px 0px 5px  #4D90FE;
 }