.waiver-pick-up {
    background-color: transparent;
    border: 2px solid var(--flow-blue);
    color: var(--flow-blue);
    font-weight: 500;
    border-radius: 5px;
    transition: all .2s ease;
}
.waiver-pick-up:hover {
    background-color: var(--flow-blue);
    color:#fff;
  }
.waiver-player-search {
    height:40px;
    color:#6e6d7a;
    background:#f3f3f4;
    box-shadow: 0px 0px 0px 1px #e7e7e9 inset;
    border-radius:8px;
    outline:none;
    padding-left: 6px;
    font-weight:500;
    font-size:15px;
    border:1px solid #6e6d7a;
}
@media only screen and (min-width : 1025px) {
    .waiver-player-search {
        position:absolute;
        left:840px;
    }
}