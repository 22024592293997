.account-dropdown {
    width: 55px;
    height: 55px;
    background-color: var(--grey);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 0.1s linear;
}
.account-dropdown:hover {
    filter: brightness(85%);
}
.icon {
    height: 37px;
}
.account-collapse {
    width: 290px;
    height: 175px;
    background-color: var(--grey);
    position: absolute;
    top: 70px;
    right: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    z-index: 4;
}
.account-collaspe-wrapper {
    margin: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    border: var(--card-highlight) 2px solid;
    border-radius: 10px;
    transition: border-color 0.15s linear;
}
.account-collapse-link {
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    height: 100%;
    padding-top: 9px;
    text-decoration: none;
    color: var(--black);
}
.account-collapse-icon {
    height: 29px;
    margin-left: 7px;
    padding-right: 7px;
}
.account-collaspe-wrapper:hover {
    border-color: var(--black);
}
