.daily-join-num-entries {
    font-size: 17px;
    margin-top: -20px;
}
.daily-join-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.daily-join-timer {
    font-weight: 600;
}
.daily-join-date {
    font-weight: 500;
    margin: 0;
    padding: 0;
    margin-bottom: 25px;
    margin-left: 5px;
}
.daily-join-summary-wrapper {
    width:100%;
    border: 3px solid #C4C4C4;
    height: 150px;
    border-radius: 10px;
    margin-top: 50px;
    overflow: auto;
}
.daily-join-summary-wrapper p {
    color: #C4C4C4;
    font-weight: 600;
    margin-top:-30px;
}
.daily-join-summary {
    padding: 10px;
    font-weight:500;
}
.daily-join-entrants-wrapper {
    width:100%;
    border: 3px solid #C4C4C4;
    height: 150px;
    border-radius: 10px;
    margin-top: 40px;
    overflow: auto;
}
.daily-join-entrants-wrapper p {
    color: #C4C4C4;
    font-weight: 600;
    position:absolute;
    top: 297px;
}
.daily-join-entrants {
    padding: 10px;
    font-weight:500;
    
}
.entrants-title {
    margin-top:-85px;
}
.daily-join-entrants-search {
    border: none;
    border-bottom: 3px solid #C4C4C4;
    width: 100%;
    height: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 3px;
    position: sticky;
}
.daily-join-lineups {
    border: 3px solid #C4C4C4;
    height: 340px;
    width: 100%;
    border-radius: 10px;
    margin-top: 50px;
    padding-bottom: 50px;
}
.daily-join-lineups p {
    color: #C4C4C4;
    font-weight: 600;
    position:absolute;
    top: -43px;
}
.lineups-join-header {
    margin-top:65px;
}
.new-lineup {
    width: 220px;
    height: 40px;
    border-radius: 10px;
    font-size: 18px;
    border: none;
    color: #fff !important;
    font-weight: 700;
    background-size: 300%;
    background-position: left;
    transition: ease 0.4s;
    padding-top: 2px;
    background-image: linear-gradient(to right, var(--orange), var(--pink), var(--red));
}
.new-lineup:hover {
    background-position: right;
    color: #fff;
}
.labl {
    display : block;
    width: 100%;
    margin-left: 20px;
    padding-right: 40px;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 50px !important;
}
.labl > input{ /* HIDE RADIO */
    visibility: hidden; /* Makes input not-clickable */
    position: absolute; /* Remove input from document flow */
}
.labl > input + div{ /* DIV STYLES */
    cursor:pointer;
    border:2px solid transparent;
}
.labl > input + div {
    border: 2px solid #000;
    border-radius: 5px;
}
.labl.selected-lineup > input + div {
    border: 2px solid var(--flow-blue);
}
.labl > div {
    padding-left: 5px;
}
.join-contest-btn {
    width: 350px;
    height: 40px;
    font-size: 18px;
    border: none;
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    background-size: 300%;
    background-position: left;
    transition: ease 0.4s;
    padding-top: 2px;
    margin-top: 12px;
    background-image: linear-gradient(to right, var(--dark-blue), var(--pink), var(--orange));
}
.join-contest-btn:hover {
    background-position: right;
    color: #fff;
}
.lineups-wrapper {
    overflow: auto;
    z-index: -1;
    height: 100%;
}
@media only screen and (max-width: 600px) {
  .daily-join-entrants-wrapper  {
    display:none;
  }
  .daily-join-lineups {
      height:150px;
  }
  .lineups-join-header {
      margin-top:50px;
  }
  .daily-join-lineups {
      margin-top:30px;
  }
  .daily-join-date {
      display:none;
  }
}