.draft-sort-select {
    height:40px;
    color:#6e6d7a;
    background:#f3f3f4;
    box-shadow: 0px 0px 0px 1px #e7e7e9 inset;
    border-radius:8px;
    outline:none;
    padding-left: 6px;
    font-weight:500;
    font-size:15px;
    margin-left:20px;
}
.draft-sort-select:hover {
    border:none;
    box-shadow: 0px 0px 0px 0px #e7e7e9,0px 0px 0px 4px rgba(153, 204, 255,.5);
    color:#0d0c22;
}
.draft-player-table-header {
    background-color:#F8F8F8;
    border-top:1px solid #000;
    height:50px;
    display:flex;
    align-items:center;
    justify-content:space-between;
}
.draft-player-search {
    height:40px;
    color:#6e6d7a;
    background:#f3f3f4;
    box-shadow: 0px 0px 0px 1px #e7e7e9 inset;
    border-radius:8px;
    outline:none;
    padding-left: 6px;
    font-weight:500;
    font-size:15px;
    border:1px solid #6e6d7a;
    margin-right:20px;
}
.draft-sort-collapse-btn {
    display:none;
    margin-left:20px;
    height:40px;
    width:100px;
    border-radius:20px;
    border:none;
    color:#fff;
    background-color:var(--flow-blue);
    font-size:17px;
    font-weight:500;
}
@media only screen and (max-width: 635px) {
    .draft-sorts {
        display:none;
    }
    .draft-sort-collapse-btn {
        display:block;
    }
    .draft-sorts-open {
        display: block;
        position:absolute;
        top:280px;
        left:20px;
        border-radius:15px;
        height:200px;
        width:200px;
        background-color: #F1F1F1;
        z-index:10;
    }
    .draft-sort-select {
        margin-top:20px;
    }
}
@media only screen and (max-width: 730px) {
    .draft-player-img {
        display:none;
    }
}
.draft-pick-up {
    height:30px;
    background-color:transparent;
    border:2px solid var(--flow-blue);
    color:var(--flow-blue);
    font-weight:600;
    border-radius:10px;
    transition: all .2s ease;
}
.draft-pick-up.can-pick {
    background-color:var(--flow-blue);
    color:#fff;
}
.draft-pick-up.can-pick:hover {
    opacity:0.8;
}