.navbar {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navbar-brand {
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.navbar-logo {
    height: 70px;
}
.navbar-brand-name {
    font-family: 'BigJohn';
    font-size: 20px;
    color: var(--flow-blue);
}
.navbar-items {
    padding-right: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}
.navbar-account {
    padding-left: 35px;
}
.navbar-link {
    font-size: 20px;
    padding-left: 35px;
    text-decoration: none;
    color: var(--black);
}
.navbar-link:hover {
    text-decoration: underline;
    text-decoration-color: var(--flow-blue);
}
.prism {
    background-image: linear-gradient(to right, var(--red) 50%, var(--purple));
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
.daily {
    background-image: linear-gradient(to right bottom, #0079F2 70%, #ffffff);
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
.mock {
    background-image: linear-gradient(to right, var(--blue) 50%, var(--yellow));
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
.collapse-control {
    display: none;
}
.navbar-collapsed-card {
    display: none;
    position: absolute;
    height: 280px;
    width: 100%;
    background-color: #ffffff;
    top: 90px;
    border: 2px solid var(--input-highlight);
    z-index: 3;
}
.collapse-btn {
    background-color: transparent;
    border: none;
    outline: none;
    width: 50px;
    padding-bottom: 10px;
    display: flex;
    align-content: center;
}
.collaspe-nav-items {
    margin-top: 30px;
}
.open {
    display: block;
}
.navbar-link-collapse {
    font-size: 20px;
    padding: 20px;
    text-decoration: none;
    color: var(--black);
}
.navbar-link-collapse:hover {
    text-decoration: underline;
    text-decoration-color: var(--flow-blue);
}
.navbar-brand-link {
    text-decoration: none;
}
@media screen and (min-width: 800px) {
    .open {
        display: none;
    }
}
@media screen and (max-width: 800px) {
    .navbar-items {
        display: none;
    }
    .collapse-control {
        display: inline;
    }
}
.daily-product-logo {
    margin-bottom: -8px;
}
.prism-product-logo {
    margin-bottom: -2px;
    margin-right: 4px;
}