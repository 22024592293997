.lobby-top-bar {
    height: 50px;
    border: 2px solid #d6d6d6;
    display: flex;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.lobby-top-bar a {
    margin: auto;
    margin: 10px;
    font-weight: 500;
    transition: color .2s linear;

    
}
a:visited { text-decoration: none; color:#000; }
.lobby-top-bar a:hover {
    color:rgba(0,0,0,0.65);
}
.plus {
    color: var(--flow-blue);
}
.lobby-table {
    overflow-x:auto;
    border-left: 2px solid #d6d6d6;
    border-right: 2px solid #d6d6d6;
    border-bottom: 2px solid #d6d6d6;
    width: 100%;
    border-collapse: collapse;
}

.lobby-table td {
    height: 50px !important;
    border-top: 2px solid #d6d6d6;
}
.daily-join {
    background-size: 300%;
    background-position: left;
    transition: ease 0.4s;
    border: none;
    color: #fff;
    height: 35px;
    width:100px;
    font-size: 17px;
    font-weight: 600;
    border-radius: 10px;
    background-image: linear-gradient(to right, var(--blue), var(--purple), var(--red));
}
.daily-join:hover {
    background-position: right;
    color: #fff;
}
.lobby-carrot {
    display:none;
}
.lobby-top-bar-collapse {
    display:flex;
    align-items: center;
}
@media only screen and (max-width: 700px) {
    
    .lobby-top-bar-collapse {
      height:160px;
      width:200px;
      border-radius:10px;
      background-color: #EEE;
      position: absolute;
      z-index:10;
      flex-direction: column;
      align-items:center;
      justify-content:space-between;
      left:160px;
      top:50px;
    }
    .lobby-top-bar-collapse {
        display:none;
    }
    .final-top-bar-dot {
        display: none;
    }
    .lobby-carrot {
        display:block;
    }
    .top-bar-open {
        display:block;
    }
  }
.top-open {
    display: flex;
}