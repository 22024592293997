.lineups-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.create-lineup {
    background-color: var(--flow-blue);
    height: 50px;
    width: 170px;
    border-radius: 10px;
    padding-top: 15px;
    padding-left: 25px;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
}
.daily-lineup-card {
    height: 610px;
    background: #EEE;
    margin: 10px;
    border-radius: 10px;
    min-width: 400px;
}

.daily-lineup-card-header { 
    font-weight: 600;
    padding-top: 5px;
    font-size: 17px;
}
.daily-lineup-card-info {
    display: flex;
    justify-content: center;
}
.daily-lineup-card-info p {
    margin: 0;
    padding: 0;
    margin-bottom: 3px;
}
.daily-lineup-card-entries {
    margin-right: 20px;
}
.daily-lineup-card-entries, .daily-lineup-card-points {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.daily-lineup-card-info-title {
    font-weight: 600;
    font-size: 21px;
}
.daily-lineup-player-fppg {
    position: absolute;
    left: 285px;
}
.daily-lineup-team-card {
    position: absolute;
    left: 205px;
}
.daily-lineup-player-recent {
    position: absolute;
    left: 330px;
    font-weight:600;
}
.lineup-controls {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
}
.lineup-sub-controls {
    display: flex;
}
.edit-lineup {
    color: #fff;
    background-color: #99ccff;
    border: none;
    height: 30px;
    width: 50px;
    border-radius: 5px;
    margin-left: 5px;
}
.lineup-locked {
    margin-left: 3px;
}
.edit-lineup.locked {
    opacity: .5;
}
.lineup-table-header {
    width: 100%;
}
.image-cropper {
    padding-top:7px;
}
tbody.lineup-body > tr > td {
    border-bottom: 1px solid #000;
    border-top:none;
}
.daily-lineup-header {
    background-color: #000;
    color: #EEE;
}
.daily-lineup-recent-total {
    margin-right: 20px;
}