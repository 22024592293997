.leaderboard-entry {
    width: 100%;
    border: 2px solid #C4C4C4;
    padding: 10px;
    height: 70px;
    margin: 5px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
}
.leaderboard-name {
    margin-left: 20px;
    font-size: 23px;
}
.leaderboard-points {
    margin-left: 20px;
    font-size: 23px;
}
.leaderboard-created {
    margin-left: 20px;
    font-size: 23px;
}
.view-lineup {
    margin-left: 20px;
    font-size: 23px;
    text-decoration: underline;
    color: #000;
}
.view-lineup:hover {
    opacity: 0.7;
}