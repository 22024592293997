.league-managers-header {
    display:flex;
    align-items:center;
    justify-content:space-between;
}
.manager-table-td {
    border-top:none;
    border:2px solid black;
}
.manager-team-btn {
    background-color:transparent;
    border:none;
    font-size:17px;
    font-weight:600;
    transition: color .2s ease;
}
.manager-team-btn:hover {
    color: rgba(0,0,0,.7)
}
.remove-team {
    height:35px;
    width:90px;
    background-color: rgba(216,35,52,.5);
    color:#fff;
    border:none;
    border-radius: 8px;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    height: 40px;
    transition: background-color 200ms ease;
}
.remove-team.admin {
    background-color: rgba(216,35,52,1);

}
.remove-team.admin:hover {
    background-color: rgba(216,35,52,.5);
}
.league-managers-invite {
    height:45px;
    width:100px;
    border-radius:20px;
    border: 2px solid var(--flow-blue);
    background-color:transparent;
    color:var(--flow-blue);
    font-size:17px;
    font-weight:500;
    transition: all .2s ease;
    background-color:var(--flow-blue);
    color:#fff;
}
.draft-held {
    background-color: rgba(153, 204, 255,.5);
    border:none;
    pointer-events: none;
}