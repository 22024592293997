body {
  margin: 0;
  font-family: 'HelveticaNeue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --flow-blue: #99CCFF;
  --black: #000000;
  --blue: #00dfff;
  --dark-blue: #0079F2;
  --purple: #5b00ff;
  --pink: #ff00e7;
  --red: #ff0037;
  --orange: #ffa005;
  --yellow: #FAE744;
  --success: #0C982B;
  --success-secondary: #D7EDD3;
  --danger: #D82334;
  --danger-secondary: #F5D4D4;
  --input-text: #5A5A5A;
  --input-highlight: #E0E0E0;
  --white: #F8F8F8;
  --grey: #f5f5f5;
  --grey-secondary: #F8F8F8;
  --card-highlight: #C4C4C4;
  --acc: #013ca6;
  --big-ten: #0088ce;
  --big-east: #e41c39;
  overflow-x: hidden;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('https://api.flowfantasy.com/api/rango/files/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
      url('https://api.flowfantasy.com/api/rango/files/fonts/HelveticaNeue-Bold.woff') format('woff');
  font-weight: 600;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('https://api.flowfantasy.com/api/rango/files/fonts/HelveticaNeue-Light.woff2') format('woff2'),
      url('https://api.flowfantasy.com/api/rango/files/fonts/HelveticaNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('https://api.flowfantasy.com/api/rango/files/fonts/HelveticaNeue.woff2') format('woff2'),
      url('https://api.flowfantasy.com/api/rango/files/fonts/HelveticaNeue.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('https://api.flowfantasy.com/api/rango/files/fonts/HelveticaNeue-Medium.woff') format('woff2'),
      url('https://api.flowfantasy.com/api/rango/files/fonts/HelveticaNeue-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('https://api.flowfantasy.com/api/rango/files/fonts/HelveticaNeue-Thin.woff2') format('woff2'),
      url('https://api.flowfantasy.com/api/rango/files/fonts/HelveticaNeue-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BigJohn';
  src: url('https://api.flowfantasy.com/api/rango/files/fonts/BIG_JOHN.woff2') format('woff2'),
      url('https://api.flowfantasy.com/api/rango/files/fonts/BIG_JOHN.woff') format('woff'),
      url('https://api.flowfantasy.com/api/rango/files/fonts/BIG_JOHN.otf') format('opentype'),
      url('https://api.flowfantasy.com/api/rango/files/fonts/BIG_JOHN.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://api.flowfantasy.com/api/rango/files/fonts/Inter-Bold.woff2?v=3.15') format("woff2"),
       url('https://api.flowfantasy.com/api/rango/files/fonts/Inter-Bold.woff?v=3.15') format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://api.flowfantasy.com/api/rango/files/fonts/Inter-Regular.woff2?v=3.15') format("woff2"),
       url('https://api.flowfantasy.com/api/rango/files/fonts/Inter-Regular.woff?v=3.15') format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url('https://api.flowfantasy.com/api/rango/files/fonts/Inter-Medium.woff2?v=3.15') format("woff2"),
       url('https://api.flowfantasy.com/api/rango/files/fonts/Inter-Medium.woff?v=3.15') format("woff");
}
@font-face {
  font-family: 'Recoleta';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url('https://api.flowfantasy.com/api/rango/files/fonts/recoleta-regular-webfont.woff2') format("woff2"),
       url('https://api.flowfantasy.com/api/rango/files/fonts/recoleta-regular-webfont.woff') format("woff");
}
* {
  font-family: 'HelveticaNeue';
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
textarea:focus, input:focus{
  outline: none;
}
.acc {
  color: var(--acc);
}
.big-ten {
  color: var(--big-ten);
}
.big-east {
  color: var(--big-east);
}