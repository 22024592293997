.lineup-wrapper {
    display: flex;
}
.players {
    margin-top: 10px;
    height: 475px;
    width: 100%;
    background-color: #EEEEEE;
    margin-right: 20px;
    overflow-y: scroll;
}
.lineup {
    margin-top: 10px;
    height: 475;
    width: calc(100%-20px);
    background-color: #EEEEEE;
 
}
.player-search {
    height: 30px;
}
.player-cost {
    font-weight: 600;
    font-size: 22px;
    color: var(--success);
}
.position-select {
    display: flex;
    flex-direction: row-reverse;
    width: 100px;
    height: 30px;
}
.daily-sort-btn {
    height: 30px;
    width: 40px;
    background-color: #EEEEEE;
    border: 1px solid #000;
    border-radius: 5px;
    margin-left: 2px;
}
.daily-sort-btn.active {
    background-color: #99ccff;
    color: #fff;
}
.lineup-header {
    position: sticky;
    top: 0;
    display: flex;
    background-color: #000;
    height: 30px;
    align-items: center;

}
.team-header {
    position: sticky;
    top: 0;
    display: flex;
    background-color: #000;
    height: 30px;
    align-items: center;
}
.lineup-header p {
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
}
.team-header p {
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
}
.lineup-pos {
    padding-left: 5px;
}
.lineup-name {
    padding-left: 20px;
}
.lineup-salary {
    padding-left: 28px;
}
.lineup-team {
    padding-left: 80px;
}
.daily-lineup-player {
    height: 40px;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: space-between;
}
.daily-lineup-one {
    display: flex;
    align-items: center;
}
.daily-lineup-player-position {
    padding-left: 5px;
}
.daily-lineup-player-name {
    padding-left: 25px;
}
.daily-lineup-player-cost {
    padding-right: 20px;
}
.daily-add-player {
    margin-right: 17px;
}
.daily-lineup-static {
    justify-self: flex-end;
    display: flex;
    align-items: center;
}
.daily-add-player {
    border-radius: 50px;
    height: 30px;
    width: 30px;
    border: none;
    background-color: var(--success);
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 5px;
    padding-left: 7px;
}
.daily-remove-player {
    border-radius: 50px;
    height: 30px;
    width: 30px;
    border: none;
    background-color: var(--danger);
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 5px;
    padding-left: 6px;
    margin-right: 10px;
}
.lineup-right-header {
    font-weight: 600;
    font-size: 30px;
}
.daily-lineup-team-logo {
    display: inline;
    margin: 0 auto;
    height: 30px;
    width: auto;
    border: 1px solid #000;
}
.daily-lineup-team-logo-create {
    height: 30px;
    border: 1px solid #000;
}
.image-cropper {
    width: 30px;
    height: 30px;
    margin-right: 45px;
}
.create-lineup-btn {
    width: 350px;
    height: 40px;
    font-size: 18px;
    border: none;
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    background-size: 300%;
    background-position: left;
    transition: ease 0.4s;
    padding-top: 2px;
    margin-top: 12px;
    background-image: linear-gradient(to right, var(--pink), var(--orange), var(--yellow));
}
.create-lineup-btn:hover {
    background-position: right;
    color: #fff;
}
.create-lineup-btn.disabled {
    opacity: 0.5;
}
.create-lineup-left-header {
    display:flex;
    align-items:center;
    justify-content:space-between;
}
.load-more-player {
    background-color: #000;
    border-top: none;
    border-left:none;
    color:#fff;
    width:250px;
    height:40px;
    border-radius:10px;
    font-size: 17px;
    margin:10px;
}
.create-lineup-right-header {
    margin-top:-7px;
    display:flex;
    align-items:center;
    justify-content:space-between;
}
.reset-lineup-logo-margin {
    margin:0;
}
.players-column {
    margin-right:10px;
    margin-left:10px;
}