.draft-message-wrapper {
    position: fixed;
    display: block;
    bottom: 0;
    background-color: #F8F8F8;
    width: 100%;
    height: 200px;
}
.draft-message {
    width: 100%;
    height: 160px;
    background-color: #F1F1F1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}
.text-message {
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
}
.message-input {
    width: 90%;
    height: 40px;
    border: none;
    font-size: 17px;
    padding-left: 10px;
}
.send-text {
    width: 10%;
    height: 40px;
    border: none;
    background-color: var(--flow-blue);
    color: #ffffff;
    outline: none;
    font-size: 20px;
    font-weight: 600;
}
.draft-message * {
    overflow-anchor: none;
}
.anchor {
    overflow-anchor: auto;
    height: 1px;
}
.message {
    padding-left: 1px;
    margin: 10px;
}