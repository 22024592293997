.wrapper{
  text-align:center;
  margin:20px auto;
}
.tabs{
  font-size:15px;
  padding:0px;
  list-style:none;
  background:#fff;
  box-shadow:0px 5px 20px rgba(0,0,0,0.2);
  display:inline-block;
  border-radius:50px;
  position:relative;
}

.tabs a{
  text-decoration:none;
  color: #000;
  padding:10px 30px;
  display:inline-block;
  position:relative;
  z-index:1;
  font-weight: 600;
  font-size: 20px;
  border-radius: 50px;
  transition: background-image .2s linear;
}

#lobby.active {
  color: #fff;
  background-image: linear-gradient(95.73deg, #DA2363 0%, #911BB6 0.01%, #FF263B 100%);
}

#lineups.active {
  color: #fff;
  background-image: linear-gradient(95.73deg, #0079F2 0%, #0079F2 0.01%, #EB70D0 100%);;
}

#contests.active {
  color: #fff;
  background-image: linear-gradient(95.73deg, #E73243 0%, #FFCB08 0.01%, #FF5969 100%);
}