.league-select {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 2px solid var(--color-gray);
	padding: 10px;
    background-color: var(--color-bg);
    margin-top: 20px;
    font-weight: 500;
    font-size: 16px;
    color: #767676;
}
.form-controls {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.form-control {
    background-color: #99ccff;
    border: none;
    font-size: 17px;
    height: 40px;
    width: 65px;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    outline: none;
}
.pll-type-select-btn {
    height: 315px;
    width: 200px;
    background: transparent;
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 10px;
    border: 2px solid #000;
    outline: none;
}
.type-image {
    height: 280px;
}
.pll-type-select {
    margin-bottom: 20px;
}
.finish {
    background-image: linear-gradient(to right, var(--red), var(--purple), var(--blue));
    background-size: 300%;
    background-position: left;
    transition: ease 0.4s;
    font-weight: 600;
}
.finish:hover {
    background-position: right;
}