.league-main {
    margin-left:250px;
    padding-left:30px;
    padding-right:30px;
}
@media only screen and (max-width: 800px) {
  .league-main {
    margin-left:100px;
  }
  
  
}
@media only screen and (max-width: 1300px) {
  .league-card-details {
    display: none;
  }
  .news-card {
    display:none;
  }
  
}
@media only screen and (max-width: 768px) {
  .league-team-card {
    margin-bottom: 30px;
  }
  .league-dashboard-card {
    margin-bottom: 30px;
  }
}
.league-team-card {
  height: 200px;
  border: 1px solid #BDC5CD;
  border-radius: 15px;
  min-width:200px;
}
.quick-links-card {
  height: 200px;
  border: 1px solid #BDC5CD;
  border-radius: 15px;
  min-width:200px;
}
.league-dashboard-card {
  height: 230px;
  border: 1px solid #BDC5CD;
  border-radius: 15px;
  border-top: 20px solid var(--flow-blue);
}
.league-notes-card {
  height: 170px;
  border: 1px solid #BDC5CD;
  border-radius: 15px;
}
.news-card {
  height: 500px;
  border: 1px solid #BDC5CD;
  border-radius: 15px;
}
.league-activity-card {
  min-height: 170px;
  border: 1px solid #BDC5CD;
  border-radius: 15px;
  margin-bottom:50px;
}
.team-image-cropper {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.team-logo {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
.league-team-roster-btn {
  border-radius:30px;
  border: 1px solid var(--flow-blue);
  background-color: transparent;
  color:var(--flow-blue);
  height:35px;
  width:120px;
  font-size: 17px;
  font-weight: 600;
  margin-top: 5px;
  transition: background-color .2s ease;
}
.league-team-roster-btn:hover {
  background-color: var(--flow-blue);
  color:#fff;
}
.league-card-header {
  display:flex;
  justify-content: space-between;
}
.league-card-details {
  margin-top: 10px;
  margin-right:10px;
}
.league-card-details p {
  margin: 3px;
}
.league-card-btns {
  display:flex;
  justify-content:center;
}
.league-card-btn {
  border-radius:30px;
  border: 1px solid var(--flow-blue);
  background-color: transparent;
  color:var(--flow-blue);
  height:35px;
  width:120px;
  font-size: 17px;
  font-weight: 600;
  margin-top: 5px;
  transition: background-color .2s ease;
}
.league-card-btn:hover {
  background-color: var(--flow-blue);
  color:#fff;
}
.league-card-footer {
  margin-top:30px;
  width: 90%;
  background-color: #F3F3F3;
  height:60px;
  border-radius:15px;
}
.league-card-message {
  padding-top: 10px;
  color: var(--danger);
}
.league-card-message-btn {
  width: 40%;
  height:30px;
  margin: 5px;
  margin-top:15px;
  border-radius:15px;
  font-weight:600;
}
.managers-btn {
  background-color: var(--flow-blue);
  border:none;
  color:#fff;
}
.schedule-btn {
  border: 1px solid var(--flow-blue);
  color: var(--flow-blue);
}
.league-card-link {
  height:30px;
  font-size:20px;
  display:flex;
  align-items: center;
  background-color:transparent;
  border:none;
}
.league-card-link img {
  height:24px;
  padding-right:3px;
}
.league-note-card-header {
  display: flex;
  align-items:center;
  justify-content: space-between;
}
.league-note {
  padding-left:15px;
  padding-right:10px;
  color:#6c6d6f;
  margin-top:0px;
  overflow:auto;
  width:100%;
  word-wrap: break-word;
}
.league-links-card {
  height: 200px;
  border: 1px solid #BDC5CD;
  border-radius: 15px;
  min-width:200px;
}
.league-chat-card-header {
  display: flex;
  align-items:center;
  justify-content: space-between;
}
.league-chat-card {
  height: 350px;
  border: 1px solid #BDC5CD;
  border-radius: 15px;
}
.league-chat-form {
  position:absolute;
  bottom:0px;
  display: flex;

}
.league-chat-send {
  height: 35px;
  width: 80px;
  border-radius: 20px;
  margin-right: 10px;
  border:1px solid #cbccce;
  color:#cbccce;
  background-color: transparent;
  transition: all .2s ease;
}
.league-chat-send.active {
  background-color: #06c;
  border: none;
  color:#fff;
}
.league-chat {
  overflow:auto;
  height: 270px;
  margin-top:-8px;
}
.full-league-chat {
  overflow:auto;
  height: 500px;
  margin-top:-8px;
}
.league-activity-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.league-activity-body th, .league-activity-body td {
  border: 1px solid black;
  font-weight:400;
  color:#797b7d;
  font-size:15px;
}
.draft-scheduler-btn {
  margin-top:50px;
  width:90px;
  height:40px;
  border-radius:20px;
  border:none;
  background-color:var(--flow-blue);
  color:#fff;
  font-size:17px;
  font-weight:600;
}
.league-note-save-btn {
  margin-top:20px;
  height:40px;
  width:80px;
  background: transparent;
  border:2px solid var(--flow-blue);
  border-radius:20px;
  color:var(--flow-blue);
  font-weight: 500;
  font-size:16px;
  transition: all .2s ease;
}
.league-note-save-btn.ready {
  background-color:var(--flow-blue);
  color:#fff;
}
.league-note-wrapper {
  height:110px;
  overflow:scroll;
}
.start-draft-btn {
  width: 200px;
  height: 40px;
  background-color: transparent;
  border:2px solid var(--flow-blue);
  border-radius: 20px;
  color:var(--flow-blue);
  font-size: 18px;
  font-weight: 400;
  transition: all .2s ease;
  margin-top: 20px;
}
.start-draft-btn:hover {
  color:#fff;
  background-color: var(--flow-blue);
}
@media only screen and (min-width: 588px) {
  .full-league-chat {
    border: 2px solid #000000;
    border-radius:15px;
  }
}