.draft-info {
    display: flex;
    width: 180px;
    min-width: 180px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    
}
.draft-details {
    height: 110px;
    display: flex;
}
.draft-timer {
    font-size: 50px;
    font-weight: 600;
}
.time-remaining {
    margin: 0px;
    font-size: 19px;
}
.round-pick {
    margin: 0px;
    color: #F3C103;
}
.picks {
    display: flex;
    overflow-x: hidden;
}
