.sidenav {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
    border-right: 2px solid #BDC5CD;
    display:flex;
    flex-direction:column;
    align-items:center;
}
.league-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left:100px;
}
@media only screen and (max-width: 800px) {
  .sidenav {
    width:100px;
  }
  .league-loader {
      margin-left:50px;
  }
  .sidenav-link-text {
      display:none;
  }
  .sidenav-link {
      border-radius: 50px;
  }
}
.sidenav-link {
    display:flex;
}
.sidenav-link-icon svg{
    stroke: var(--black);
}
.sidenav-link-text {
    color: var(--black);
    font-weight: 600;
    font-size: 22px;
    margin-left:10px;
}
.sidenav-link {
    display: flex;
    align-items: center;
    padding:15px;
    padding-right:20px;
    padding-left:20px;
    border:none;
    background-color: #fff;
    transition: background-color .2s ease;
    border-radius:30px;
}
.sidenav-link:hover {
    background-color: rgb(29,161,242,.1);
}
.sidenav-link.sidenav-active .sidenav-link-icon svg {
    stroke: var(--flow-blue);
    fill: var(--flow-blue);
}
.sidenav-link.sidenav-active .sidenav-link-text {
    color: var(--flow-blue)
}