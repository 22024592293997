.prism-brand {
    height: 60px;
}
.headline {
    margin-bottom: 0px;
}
.bullet {
    font-weight: 500;
    margin:15px;
    font-size: 30px;
}
.cost {
    font-weight:600;
}
.price {
    background-image: linear-gradient(to bottom right, var(--red) 50%, var(--purple));
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
.form-btn {
    width: 100%;
    height: 40px;
    font-size: 18px;
    border: none;
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    background-size: 300%;
    background-position: left;
    transition: ease 0.4s;
    margin-top: 30px;
    padding-top: 2px;
    max-width: 400px;
    margin-top: 3px;
}
.buy-now {
    background-image: linear-gradient(to right, var(--red), var(--purple), var(--pink));
}