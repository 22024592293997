.draft-card {
    min-width: 120px;
    height: 100%;
    border: double 2px transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 10px;
    display: flex;
    margin-right: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.team-image {
    height: 40px;
}
.team-image-wrapper {
    border: 2px solid #C4C4C4;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.team-title {
    padding-top: 5px;
    font-weight: 600;
}