.daily-schedule {
    height: 95px;
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
}
.daily-schedule-scrollable {
    display: flex;
    overflow-x: auto;
}
.all-games {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 20px;
    border-bottom: double 5px transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;  
}
.daily-game {
    background-color: #EEE;
    margin: 0 20px 0 20px;
    min-width: 150px;
    border-radius: 10px;
    font-size: 17px;
}
.daily-game-home {
    padding-left: 15px;
    font-weight: 600;
    padding-top: 13px;
}
.daily-game-away {
    padding-left: 15px;
    padding-bottom: 6px;
}
.daily-game-time {
    padding-left: 15px;
    margin-bottom: -5px;
}
.daily-game-broadcast {
    padding-left: 15px;
    font-size: 15px;
    color: #005EBB;
}
.daily-game-broadcast:hover {
    text-decoration: underline;
}
.daily-game-broadcast:visited {
    color: #005EBB;
}