.setting-section-title {
    font-weight: 500;
}
.icon-title {
    font-size: 20px;
    font-weight:600;
}
.saved-status {
    display: flex;
    align-contents: center;
}
.saved-icon {
    height: 30px;
}
.saved-text {
    padding-left: 7px;
    margin-top: 4px;
    font-size: 19px;
    color: var(--flow-blue)
}
.not {
    color: var(--card-highlight);
}
.true {
    color: var(--flow-blue)
}
* {
    box-sizing: border-box;
}
input[type="radio"] {
    display: none;
}
input[type="radio"]:checked + label span {
    transform: scale(1.25);
}
input[type="radio"]:checked + label .red {
    border: 2px solid #711313;
}
input[type="radio"]:checked + label .green {
    border: 2px solid #0e4e1d;
}
input[type="radio"]:checked + label .teel {
    border: 2px solid #22a39c;
}
input[type="radio"]:checked + label .light_blue {
    border: 2px solid #103f62;
}
input[type="radio"]:checked + label .purple {
    border: 2px solid #501962;
}
label {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    cursor: pointer;
}
label:hover span {
    transform: scale(1.25);
}
label span {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-in-out;
}
label span.red {
    background: #ED4849;
}
label span.brown {
    background: #483633;
}
label span.black {
    background: #000000;
}
label span.green {
    background: #2B382D;
}
label span.teel {
    background: #96D7DB;
}
label span.light_blue {
    background: #0EA2C0;
}
label span.purple {
    background: #978CD8;
}
.service {
    display: flex;
    align-items: center;
}
.service-name {
    font-weight: 600;
    font-size: 20px;
    margin-right: 40px;
}
.remove-service {
    height: 30px;
    margin-top: 2px;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: var(--danger);
    color: var(--white);
}
@-webkit-keyframes fill {
    to {
        background-size:100% 0;
    }
}
@keyframes fill {
    to {
        background-size:100% 0;
    }
}
.cnh_holding button{
    background: -webkit-linear-gradient( white , white) rgb(255, 189, 195) no-repeat 0 0;
    background: linear-gradient( white , white) rgb(255, 189, 195) no-repeat 0 0;
    mix-blend-mode: multiply;
    background-size:  100% 100%;
    -webkit-animation: fill 2s forwards;
    animation: fill 2s forwards;
}
.delete-btn {
    height: 50px;
    width: 170px;
    border: 3px solid var(--danger);
    background-color: transparent;
    color: var(--danger);
    border-radius: 15px;
    font-size: 20px;
    font-weight: 600;
    outline: none;
    margin-left: 10px;
}
.jargon-item {
    color: var(--flow-blue);
    font-weight: 600;
    text-decoration: none;
}
.jargon-item:hover {
    text-decoration: underline;
}