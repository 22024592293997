.prism-brand-dashboard {
    height: 40px;
}
.prism-card {
    margin-top: 15px;
    height: 500px;
    background-color: #EEEEEE;
    border-radius: 15px;
}
.prism-card-title {
    padding-top: 10px;
    padding-left: 15px;
    font-size: 22px;
    font-weight: 600;
}
.players-header {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.player-card {
    height: 50px;
    background-color: #E0E0E0;
    width: calc(100% - 20px);
    margin-left: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: space-evenly;
}
