.leaderboard-player-name {
    margin-left: 20px;
    font-size: 23px;
}
.leaderboard-player-team-logo-wrapper {
    margin-left:40px;
}
.leaderboard-player-cost {
    margin-left: 40px;
    font-size: 23px;
    color: var(--success)
}