.contest-name {
    margin-top: -3px;
    margin-bottom: 10px;
}
.contest-view-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contest-status {
    margin-right: 50px;
    margin-top: -1px;
}
.PreGame {
    color: var(--orange);
}
.InProgress {
    color: var(--success);
}
.Complete {
    color: var(--danger);
}
.contest-start {
    margin: 0;
    font-weight: 400;
}
.contest-end {
    margin: 0;
    font-weight: 400;
}
.contest-view-subheader {
    display: flex;
    justify-content: space-between;
}
.contest-code {
    margin-bottom: 10px;
    margin-right: 5px;
}
.contest-entries {
    width:100%;
    border: 3px solid #C4C4C4;
    height: 400px;
    border-radius: 10px;
    margin-top: 40px;
    overflow: auto;
}
.contest-entries p {
    color: #C4C4C4;
    font-weight: 600;
    position:absolute;
    top: 130px;
}
.contest-search {
    border: none;
    border-bottom: 3px solid #C4C4C4;
    width: 100%;
    height: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 3px;
}
.neutral {
    color: #000;
}
.positive {
    color: var(--success);
}
.negative {
    color: var(--danger);
}
.user-lineup {
    text-decoration: underline;
    color: #000;
}
.winner-winner-chicken-dinner {
    border: 2px var(--success) solid;
    height: 50px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 10px;
}
.delete-entrant {
    margin-left: 30px;
    border: 2px solid var(--danger);
    background-color: #fff;
    color: var(--danger);
    height: 30px;
    border-radius: 5px;
    transition: all .2s linear;
}
.delete-entrant:hover {
    background-color: var(--danger);
    color: #fff;
}
@media only screen and (max-width: 600px) {
    .contest-start {
        display:none;
    }
    .contest-end {
        display:none;
    }
    .contest-status {
        display: none;
    }
    .contest-entries {
        margin-top:50px;
    }
}