.form-wrapper {
    padding-bottom: 10px;
}
.form-input {
    width: 100%;
    height: 60px;
    border-style:solid;
    border-color: #E0E0E0;
    border-radius: 17px;
    padding-left: 20px;
    color: var(--input-text);
    transition: border-color 0.1s;
    display: flex;
    align-items: center;
    font-size: 17px;
}
.form-input:focus{
    border-color: var(--input-text);
}
.status {
    padding-top: 2px;
    padding-right: 5px;
}
.input-wrapper {
    margin-bottom: 20px;
}
.input-error {
    margin-left: 5px;
    color: var(--danger);
}
.form-btn {
    width: 100%;
    height: 40px;
    font-size: 18px;
    border: none;
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    background-size: 300%;
    background-position: left;
    transition: ease 0.4s;
    margin-top: 30px;
    padding-top: 2px;
    margin-top: 3px;
}
.form-btn:hover {
    background-position: right;
    color: #fff;
}
.login-submit {
    background-image: linear-gradient(to right, var(--blue), var(--purple), var(--red));
}
.signup-submit {
    background-image: linear-gradient(to right, var(--purple), var(--pink), var(--red));
}
.reset-email-submit {
    background-image: linear-gradient(to right, var(--pink), var(--orange), var(--yellow));
}
.help-wrapper {
    font-weight: 700;
    margin-bottom: 3px;
    margin-top: 3px;
}
.help {
    text-decoration: none;
    font-weight: 400;
    color: var(--black);
}
.help:hover {
    text-decoration: underline;
}
.form-error {
    margin-top: 40px;
    color: var(--danger);
    margin-left: 5px;
    padding-bottom: 5px;
}