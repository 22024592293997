.box-score-btn-wrapper {
    width: 140px;
    border-left: 1px solid rgb(220, 221, 223);
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-score-btn {
    background-color: transparent;
    border: 1px solid var(--flow-blue);
    color:var(--flow-blue);
    font-size: 15px;
    padding: 5px;
    width: 90px;
    border-radius: 9px;
    transition: all .2s ease;
}
.box-score-btn:hover {
    color: #fff;
    background-color: var(--flow-blue);
}
@media only screen and (max-width: 515px) {
    .box-score-btn-wrapper {
        display: none;
    }
}