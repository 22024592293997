.notification {
    width: 100%;
    height: 90px;
    border: 2px solid #e5e5e5;
    margin-bottom: 10px;
    border-radius: 15px;
    display: flex;
    padding: 10px;
}
.unread {
    background-color: #e5e5e5;
}