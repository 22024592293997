.code-input {
    width: 150px;
    height: 60px;
    border-style:solid;
    border-color: #E0E0E0;
    border-radius: 17px;
    padding-left: 20px;
    color: var(--input-text);
    transition: border-color 0.1s;
    display: flex;
    align-items: center;
    font-size: 17px;
}
.code-input:focus{
    border-color: var(--input-text);
}
.join-error {
    color: var(--danger);
    margin-top: 5px;
}