.nav {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
}
.brand {
    display: flex;
    align-items: center;
    padding-left: 20px;
}
.logo {
    height: 70px;
}
.brand-name {
    font-family: 'BigJohn';
    font-size: 20px;
    color: var(--flow-blue);
    font-weight: 500;
}