.summary-area {
    width: 100%;
    height: 70px;
    border-radius: 15px;
    border: 2px solid #E0E0E0;
}
.public-selector {
    display: flex;
    flex-direction: column;
}
.timezone-select {
    height: 30px;
}
.daily-select {
    height: 40px;
    width: 100px;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
    font-size: 15px;
}