.contests-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contests-table-header {
    border-bottom: 2px solid #C4C4C4;
}
.no-contests {
    border-bottom: none !important;
    margin-top: 50px;
}
.contest-sort-select {
    height: 40px;
    margin-right: 30px;
    font-weight: 600;
    font-size: 15px;
    border-color: #C4C4C4;
    border-radius: 5px;
    outline: none;
}