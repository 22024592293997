.league-card {
    width: 100%;
    border: double 3px transparent;
    border-radius: 80px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    height: 200px;
    min-width: 180px;
    max-width: 300px;
    border-radius: 15px;
}
.league-title {
    position: absolute;
    padding-top: 10px;
    padding-left: 15px;
    font-size: 22px;
    font-weight: 610;
    color: var(--black);
}
.nll {
    position: absolute;
    top: 37px;
    padding-left: 15px;
    font-size: 20px;
    font-weight: 700;
    color: #636468;
}
.pll {
    position: absolute;
    top: 37px;
    padding-left: 15px;
    font-size: 20px;
    font-weight: 700;
    color: #FFCB08;
}